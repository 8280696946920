import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';


import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handle.service';
import {Pagination} from "./pagination";
import {common} from "./common";
import {ApiFilters} from "./filter";
import {forEach} from "@angular/router/src/utils/collection";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public apiUrl = '/api/';  // URL to web api
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AppService');
  }

  get (route, pagination: Pagination = new Pagination(), filters = new ApiFilters()): Observable<any> {

    let params_str = "";

    if(pagination.CurrentPage && pagination.PerPage) {

      params_str  = "?page=" + pagination.CurrentPage;
      params_str += "&per_page=" + pagination.PerPage;

    }

    if(filters) {

      for(let key in filters) {

        if (filters[key]) {
          params_str += "&" + key + "=" + filters[key];
        }
      }
    }

    return this.http.get(
      this.apiUrl + route + params_str,
       this.getHttpOptions(),
    )
      .pipe(
        catchError(this.handleError('getData', []))
      );
  }

  post (route, model: any): Observable<any> {
    return this.http.post(this.apiUrl + route, model, this.getHttpOptions())
      .pipe(
        catchError(this.handleError(route, model))
      );
  }

  put (route, model: any): Observable<any> {
    return this.http.put(this.apiUrl + route, model, this.getHttpOptions())
      .pipe(
        catchError(this.handleError(route, model))
      );
  }

  getCurrentUser(callback) {

    this.get('user/current?getCurrentUser', callback)
      .subscribe((res) => {

        if(res && res.data && res.data.ID) {
          callback(res.data);
        } else {
          callback(null);
        }

      });
  }

  public getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'AuthorizationUser': common.getToken()
      })};
  }
}

