
export class ApiFilters {
  IsDeleted: boolean;
  IsPublic: boolean;
  IsHasData: boolean;
  Keywords: string;
  Tags: Array<string>;
  MaxPrice: number;
  MinPrice: number;
  MaxError: number;
  MinError: number;
  Payment: Array<string>;
}
