import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginComponent} from "./login/login.component";
import {
  MatButtonModule, MatCheckboxModule, MatIconModule,
  MatInputModule,
  MatPaginatorIntl,
  MatSlideToggleModule,
  MatToolbarModule
} from "@angular/material";
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {FooterComponent} from "./footer/footer.component";
import {RouterModule, Routes} from "@angular/router";
import {RoutingState} from "./rounting.state";
import {HttpErrorHandler} from "./services/http-error-handle.service";
import {MessageService} from "./services/message.service";
import {MatPaginatorIntlTsv} from "./services/paginatorClass";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

const appRoutes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'dk-admin' }),
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    HttpClientModule,
  
  
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
  ],
  providers: [RoutingState, { provide: LOCALE_ID, useValue: 'ru' }, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTsv}, HttpErrorHandler, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
