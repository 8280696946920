import {isUndefined} from "util";

export abstract class common {

  public static tokenKey = "AuthorizationUser";
  public static minTokenLength = 10;
  public static storage;
  public static isRememberKey = "isRemember";

  public static initStorage() {

    if(!common.storage) {

      if (this.isRemember()) {

        common.storage = localStorage;

      } else {

        common.storage = MemoryStorage();
      }

    }
  }

  public static isRemember() {
    return  localStorage.getItem(common.isRememberKey) == "true" || localStorage.getItem(common.isRememberKey) === null;
  }

  public static getToken() {
    return this.isValidToken() ? this.storage.getItem(common.tokenKey) : '';
  }

  public static setToken(token) {

    if(!this.isRemember()) {
      this.storage = MemoryStorage();
    } else {
      this.storage = localStorage;
    }

    this.storage.setItem(common.tokenKey, token);
  }

  public static clearToken() {
    this.initStorage();
    this.storage.setItem(common.tokenKey, '');
  }

  public static isValidToken() {
    this.initStorage();
    return this.storage.getItem(common.tokenKey) &&
      this.storage.getItem(common.tokenKey).length >= common.minTokenLength;
  }

  public static getErrorMessage(Field) {
    return Field.hasError('required') ? 'Пожалуйста, заполните поле' :
      Field.hasError('email') ? 'Неверно указан email' :
        '';
  }

}

function MemoryStorage() {

  let storage = {};

  function getItem(key) {

    if (!storage.hasOwnProperty(key)) {
      return undefined;
    }

    return storage[key];
  }

  function setItem(key, value) {
    storage[key] = value;
  }

  return {
    getItem: getItem,
    setItem: setItem,
  }
}
