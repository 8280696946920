import { Injectable } from '@angular/core';
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {CurrentUserService} from "../services/current-user.service";
import {common} from "../services/common";

@Injectable({
  providedIn: 'root'
})
export class AutologinService {

  constructor(private api: ApiService, public router: Router, public currentUser: CurrentUserService) { }

  public tryAutoLogin() {

    if(!common.isValidToken()) { return false; }

    this.api.get('user/current?autologin')
      .subscribe((res) => {

        if(res && res.data && res.data.ID) {
          this.currentUser.loadCurrentUser();
          this.router.navigate(['/cabinet']);
        }

      });
  }

}
