import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {common} from "./common";

const ROLE_DEVELOPER = 1;
const ROLE_CUSTOMER = 2;
const ROLE_ALL = 3;

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  currentUser: any = null;

  constructor(private api: ApiService) {

    setTimeout(()=>{
      this.loadCurrentUser();
    });

  }

  public clearCurrentUser() {
    this.currentUser = null;
  }

  public loadCurrentUser() {

    if(!common.isValidToken()) { return false; }

    this.api.get('user/current?loadCurrentUser')
      .subscribe((res) => {

        if(res && res.data && res.data.ID) {
          this.currentUser = res.data;
          common.setToken(res.data.Token)
        } else {
          this.currentUser = null;
        }

      });
  }

  public auth(login, password, successfunction, errorFunction): void {

    this.api.post('user/auth', {Email: login, Password: password})
      .subscribe((res) => {

        if(res && res.data && res.data.Token) {

          common.setToken(res.data.Token);
          this.loadCurrentUser();

          setTimeout(()=> {
            successfunction(res.data)
          }, 200);

        } else {
          errorFunction(res);
        }

      });
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  public isValidCurrentUser() {
    return (this.currentUser &&
      this.currentUser.ID &&
      this.currentUser.ID.length &&
      this.currentUser.ID.length > 0);
  }

  public isCustomer() {
    if(!this.currentUser) {return false;}
    return this.currentUser.Role == ROLE_ALL || this.currentUser.Role == ROLE_CUSTOMER;
  }

  public isDeveloper() {
    if(!this.currentUser) {return false;}
    return this.currentUser.Role == ROLE_ALL || this.currentUser.Role == ROLE_DEVELOPER;
  }

  public isDeveloperAndCustomer() {
    if(!this.currentUser) {return false;}
    return this.currentUser.Role == ROLE_ALL;
  }

  public getCabinetUrl() {
    if(!this.currentUser) {return '/';}
    return '/cabinet/catalog';
  }

  public getProjectsUrl() {
    if(!this.currentUser) {return '/';}
    return this.getCabinetUrl();
  }

  public logout() {
    common.clearToken();
    this.clearCurrentUser();
  }

}

