import { Injectable } from '@angular/core';
import {forEach} from "@angular/router/src/utils/collection";

export interface Message {
  Service: string,
  Route: string,
  Message: any
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private messages: Message[] = [];

  add(message: Message) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  popLast(route) {

    for(let i=0; i<this.messages.length; i++) {
      if(this.messages[i].Route == route) {

        return this.messages.pop();

      }
    }

    return <Message>{};
  }
}

