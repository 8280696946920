import {Component, OnInit, Renderer2} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {BodyBackground} from '../bodyBackground';
import {RoutingState} from "../rounting.state";
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {AutologinService} from "./autologin.service";
import {common} from "../services/common";
import {CurrentUserService} from "../services/current-user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  Login = new FormControl('', [Validators.required]);
  Password = new FormControl('', [Validators.required]);
  isRemember = common.isRemember();

  public bg: BodyBackground;

  isWrongPass = false;

  constructor(
    private routingState: RoutingState,
    private renderer: Renderer2,
    private api: ApiService,
    private router: Router,
    private autologin: AutologinService,
    private currentUser: CurrentUserService
  ) {
    this.bg = new BodyBackground(renderer);
  }

  getErrorMessage(Field) {

    return Field.hasError('required') ? 'Пожалуйста, заполните поле' :
      '';
  }

  updateSaver(eve) {

    this.isRemember = eve.checked;
    localStorage.setItem(common.isRememberKey, JSON.stringify(this.isRemember));
  }

  auth(): void {

    this.isWrongPass = false;

    this.currentUser.auth(
      this.Login.value,
      this.Password.value,
      ()=>{
        this.router.navigate([this.currentUser.getCabinetUrl()]);
      },
      ()=>{

        setTimeout(()=>{
          this.isWrongPass = true;
        }, 500);

      });

  }

  ngOnInit() {
    this.bg.setBg('bglogin');
    this.autologin.tryAutoLogin()
  }



  ngOnDestroy() {
    this.bg.restoreBg('bglogin');
  }

  enter(event) {
    if(event.keyCode == 13) { this.auth(); }
  }

}
