import {Renderer2} from "@angular/core";

export class BodyBackground {

  constructor(private renderer: Renderer2) {

  }

  public restoreBg(body_class) {

    let no_bg_footer = 'nobg';
    let regex_body = new RegExp('\\s+' + body_class, 'g');
    let regex_footer = new RegExp('\\s+' + no_bg_footer, 'g');

    this.renderer.setAttribute(document.body, 'class', document.body.getAttribute('class').replace(regex_body,''));

    let footer = document.getElementById("footer");

    if(footer)
      this.renderer.setAttribute(
        footer,
        'class',
        footer.getAttribute("class").replace(regex_footer,'')
      )
  }

  public setBg(body_class) {

    let no_bg_footer = 'nobg';
    let regex_footer = new RegExp('/\w+' + no_bg_footer + '/g');

    this.renderer.setAttribute(document.body, 'class', (document.body.getAttribute('class') || '') + ' ' + body_class);
    let footer = document.getElementById("footer");
    if(footer)
      this.renderer.setAttribute(
        footer,
        'class',
        (footer.getAttribute("class") || '') + ' ' + no_bg_footer);
  }


}
