import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import { Observable, of } from 'rxjs';

import {Message, MessageService} from './message.service';
import {Router} from "@angular/router";
import {common} from "./common";
import {catchError} from "rxjs/operators";

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  constructor(
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient,
  ) { }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>  (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T> (serviceName = '', operation = 'operation', result = {} as T) {

    return (response: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure

      // Если мы не авторизованы для данной операции, возможно вообще не авторизованы
      // и нужно обнулять токен.
      // Это правило не должно работать для авторизации и получения собственных данных
      if(response.status == 403
        && !response.url.match(new RegExp('user/auth'))
        && !response.url.match(new RegExp('user/current'))
      ) {

        console.error('try recheck'); // log to console instead

        this.http.get('api/user/current?recheck', {headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'AuthorizationUser': common.getToken()
          })
        })
        .pipe(
          catchError((resp, caught) => {

            if(resp.status == 403) {
              common.clearToken();
              this.router.navigate(['/cabinet']);
            }
            return Observable.create();

          })
        )
        .subscribe((res) => {

            console.error('res after recheck 403', res);

        });
      }

      const message = (response.error instanceof ErrorEvent) ?
        response.error.message :
        `server returned code ${response.status} with body "${response.error}"`;

      // TODO: better job of transforming error for user consumption
      let msg = <Message>{};

      msg.Service = serviceName;
      msg.Route = operation;
      msg.Message = response.error;

      this.messageService.add(msg);

      // Let the app keep running by returning a safe result.
      return of( result );
    };

  }
}
